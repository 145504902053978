// Here you can add other styles

@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700&display=swap');

.body {
  padding-top: 89px;
  max-height: 100vh;
}

.header {
  left: var(--cui-sidebar-occupy-start);
  right: 0;
}

.mt {
  &-n2 {
    margin-top: -0.5 * $spacer;
  }
  &-n3 {
    margin-top: -1 * $spacer;
  }
}

.mb {
  &-n2 {
    margin-bottom: -0.5 * $spacer;
  }
  &-n3 {
    margin-bottom: -1 * $spacer;
  }
}

.fs {
  &-7 {
    font-size: small;
  }
  &-8 {
    font-size: smaller
  }
}

.min-w-100 {
  min-width: 100% !important;
}

body {
  background-color: white;
}

.fade-loader {
  %spinner {
    background-color: rgb(54, 215, 183);
    width: 15px;
    height: 15px;
    margin: 2px;
    border-radius: 100%;
    display: inline-block;
  }

  .spinner1 {
    @extend %spinner;
    animation: 0.6s ease-in-out 0.07s infinite normal both running spinner;
  }

  .spinner2 {
    @extend %spinner;
    animation: 0.6s ease-in-out 0.14s infinite normal both running spinner;
  }

  .spinner3 {
    @extend %spinner;
    animation: 0.6s ease-in-out 0.21s infinite normal both running spinner;
  }

  @keyframes spinner {
    33% {
      transform: translateY(10px);
    }
    66% {
      transform: translateY(-10px);
    }
    100% {
      transform: translateY(0px);
    }
  }
}

.k {
  &-round {
    border-radius: $k-border-radius;
  }
  &-bg-1 {
    background-color: $primary-100;
  }
  &-bg-2 {
    background-color: $primary-200;
  }

  &-avatar .avatar-img {
    max-height: 100%;
    max-width: 100%;
    height: auto;
    width: auto;
  }

  &-head tr:first-child th {
    text-wrap: nowrap;

    span {
      float: none !important;
    }
  }
}

.card {
  border-radius: $k-border-radius;

  &-light {
    -webkit-box-shadow: 0px 0px 15px -5px rgba(0, 0, 0, 0.25);
    -moz-box-shadow: 0px 0px 15px -5px rgba(0, 0, 0, 0.25);
    box-shadow: 0px 0px 15px -5px rgba(0, 0, 0, 0.25);
  }
  &-dark {
    background-color: $primary-800;
  }
  &-header:first-child {
    border-radius: $k-border-radius $k-border-radius 0 0;
    //border-radius: calc(1.5rem - (var(--cui-border-width))) calc(1.5rem - (var(--cui-border-width))) 0 0;
  }

  &-footer:last-child {
    border-radius: 0 0 $k-border-radius $k-border-radius;
  }

  &-table {
    border-color: var(--#{$prefix}border-color);
    box-shadow: none;
  }

  &-action {
    display: flex;
    justify-content: end;
    background-color: unset;
    border-top: none;
  }
}

.dropdown-item-focus {
  color: var(--#{$prefix}dropdown-link-hover-color);
  text-decoration: if($link-hover-decoration == underline, none, null);
  @include gradient-bg(var(--#{$prefix}dropdown-link-hover-bg));
}

.tooltip {
  --cui-tooltip-bg: #{$primary-900};
}

.nav-tabs {
  .nav-link{
    padding-top: 0.25 * $spacer;
    padding-bottom: 0.25 * $spacer;

    &:not(.active) {
      background-color: $gray-100;

      &:hover {
        background-color: $gray-200;
      }
    }
  }
}

tbody {
  > tr > td > .k-not-hover {
    display: none;
  }
  > tr:hover > td > .k-not-hover {
    display: flex;
  }
}

.header-search-group:focus-within {
  color: var(--#{$prefix}body-color);
  background-color: var(--#{$prefix}body-bg);
  border-color: var(--#{$prefix}border-color);
  outline: 0;
  box-shadow: 0 0 0 0.25rem var(--#{$prefix}focus-ring-color);
  z-index: 5;
  border-radius: var(--#{$prefix}border-radius);
}

.header-search-group .form-control:focus {
  box-shadow: none !important;
}

// наезжают значки удаления и сворачивания списка
.form-multi-select-input-group {
  flex-wrap: nowrap;
}

.pagination .page-link {
  cursor: pointer;
}

.text-size-row.row-1 {
  font-size: 1.3em; /* 130% */
}

.text-size-row.row-2 {
  font-size: 1.2em; /* 120% */
}

.text-size-row.row-3 {
  font-size: 1.1em; /* 110% */
}

.text-size-row {
  font-size: 1em; /* 100% */
}
